<template>
    <div class="pageBox">
        <div class="bannerBox">
            <div class="topText">
                <div class="titleTop">馆藏文物预防性保护风险管理 </div>
                <div>监测预警 | 风险评估 | 决策支持</div>
            </div>
            <div class="btnsBox">
                <div class="btnsItem" @click="go(0)">全国中心</div>
                <div class="btnsItem" @click="go(1)">区域中心</div>
                <div class="btnsItem" @click="go(2)">监测站点</div>
                <div class="btnsItem" @click="go(3)">普通用户</div>
            </div>
        </div>
        <div class="JJBox">        
            <div class="title">
                <img src="../../assets/loginout/title-tips.png"/>
                <div class="titleCenter"> 平台简介 </div>
                <img src="../../assets/loginout/title-tips.png"/>
            </div>
            <div class="jianjieContain">
                <div class="line"></div>
                <div class="boxItem" @mouseenter="handleMouseEnter(index,$event)" v-for="(item, index) in topList" :key="index">
                    <img class="sideItem" :src="require(`../../assets/loginout/${item.name}.png`)"/>
                    <div class="circle"></div>
                    <div class="sideItem" >
                        {{ item.name }}
                    </div>
                    <div :class="`bgText ${currentItem==index?'bgOpens':'bgOpen'}`">
                        <div class="bgTitle">{{ item.name }}</div>
                        <div class="bgTxt">
                        {{ item.text }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="XMCGbox">
            <div class="title">
                <img src="../../assets/loginout/title-tips.png"/>
                <div class="titleCenter"> 项目成果 </div>
                <img src="../../assets/loginout/title-tips.png"/>
            </div>
            <div class="CGBox">
                <div class="CGitem" v-for="(item, index) in CGlist" :key="index">
                    <div class="lineTitle">
                        <div>{{ item.name }}</div>
                        <div class="more" @click="goMore()">更多</div>
                    </div>
                    <div class="lineMsgBox" @click="BZopen(i.path)" v-for="(i, jindex) in item.children" :key="jindex">
                        <div class="lineMsg">■ {{ i.name }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="JDcaseBox">
            <div class="title">
                <img src="../../assets/loginout/title-tips.png"/>
                <div class="titleCenter"> 经典案例 </div>
                <img src="../../assets/loginout/title-tips.png"/>
            </div>
            <div class="caseBox">
                <div class="caseItem" @click="goCase" v-for="(item, index) in caseList" :key="index">
                    <img class="caseImg" :src="require(`../../assets/loginout/${item.img}.png`)"/>
                    <div class="caseMsg">
                        <div class="caseTitle">{{ item.name }}</div>
                        <div class="caseTip">{{ item.text }}</div>
                        <div class="caseSign">
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="dataBaseBox">
            <div class="title">
                <img src="../../assets/loginout/title-tips.png"/>
                <div class="titleCenter"> 资料库 </div>
                <img src="../../assets/loginout/title-tips.png"/>
            </div>
            <div class="dataBox">
                <div class="dataItem" @click="goData" v-for="(item, index) in dataList" :key="index">
                    <div style="width: 100%;height: 100%;" v-if="index!=5">
                        <div class="topDI">
                            <div class="left">
                                <div class="dataName">{{ item.name }}</div>
                                <div class="dataLine">
                                    <div class="dataIn"></div>
                                </div>
                            </div>
                            <img class="right" :src="require(`../../assets/loginout/${item.name}.png`)" />
                        </div>
                        <div class="botDI">
                            <div v-for="(i, jindex) in item.txts" :key="jindex">
                                {{ i }}
                            </div>
                        </div>
                    </div>
                    <div style="width: 100%;height: 100%;display: flex;justify-content: center;align-items: center;" v-else>
                        <el-button type="primary" size="large" plain>查看更多</el-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="testBaseBox">
            <div class="title">
                <img src="../../assets/loginout/title-tips.png"/>
                <div class="titleCenter"> 风险评估 </div>
                <img src="../../assets/loginout/title-tips.png"/>
            </div>
            <div class="testBox">
                <div class="testItem" @click="goTest"  v-for="(item, index) in testList" :key="index">
                    <div class="testTop">
                        <img class="testImg" :src="require(`../../assets/loginout/${item.logo}.png`)"/>
                        <div class="testTitle">{{ item.name }}</div>
                    </div>
                    <img class="testBot" :src="require(`../../assets/loginout/${item.img}.png`)" />
                </div>
            </div>
        </div>
        <div class="appBaseBox">
            <div class="title">
                <img src="../../assets/loginout/title-tips.png"/>
                <div class="titleCenter"> 应用指南 </div>
                <img src="../../assets/loginout/title-tips.png"/>
            </div>
            <div class="appBox">
                <div class="appItem" @click="goApp" v-for="(item, index) in appList" :key="index">
                    <div class="appLeft">
                        <div class="appName">{{ item.name }}</div>
                        <div class="appTips">{{ item.txt }}</div>
                    </div>
                    <img class="appRight" :src="require(`../../assets/loginout/${item.img}.png`)" />
                </div>
            </div>
        </div>
        <div class="scan">
            <img class="scan-code" src="../../assets/img/scan.jpg"/>
            <div class="scan-name">
                <img class="scan-nameImg" src="../../assets/img/logowei.png"/>
                <div>博物馆预防性风险监测小程序</div>
            </div>
        </div>
        
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default{
    computed:{
      ...mapGetters([
        'user',
      ]),
    },
    data(){
        return{
            CGlist:[], //项目成果

            timer:10,
            flag:false,
            loopId:null,
            currentItem:2,
            topList:[
                {name:'系统特性', text:'本平台是一个馆藏文物预防性保护风险管理专业业务综合服务平台，旨在面向馆藏文物预防性保护全国性和区域性业务管理服务者、博物馆监测站点或个人用户，提供文物保存环境数据分析、监测预警、风险评估、决策支持、应对处置等预防性保护风险管理支持。'},
                {name:'系统功能', text:'通过分析文物展陈、储存或运输环境数据，评估环境条件对青铜器、铁器、丝织品、馆藏壁画、漆木器、纸质文物六类文物带来的风险，并给出应对决策支持预案。也可以评估文物生物病害的影响。还能学习交流馆藏文物预防性保护风险防控管理指导性资料和案例。'},
                {name:'系统应用', text:'接入博物馆环境监测系统数据、或上传各种环境场景下检测获得的环境数据或数据集，并完善相关信息，用户就可以在本系统中浏览、查阅、比对、分析那些即时监测数据、上传或储存的历史数据、或系统生成的图形数据，然后使用它们进行风险评估与决策支持。'},
                {name:'系统开发', text:'本平台是2020国家重点研发计划“重大自然灾害监测预警与防范”重点专项（文化遗产保护利用专题任务）《馆藏文物预防性保护风险防控关键技术研发示范》（项目编号：2020YFC1522500）资助、开发与维护，互联互通示范接入一批博物馆环境监测站，展示接入区域中心和全国中心的统计状况。目的是推进我国馆藏文物预防性保护从“稳定、洁净”向更加“智能、精准、节能”的风险防控管理发展。'},
                {name:'应用支持', text:'本平台向馆藏文物保存环境预防性保护风险防控单位、专业人士和决策者开放应用，不涉及馆藏文物保存环境调控控制功能，可保障各级用户的环境数据和控制安全。随着研究和应用的深入，本平台将提供更多的应用工具、指南资料和经验案例。各级用户的应用经验和数据，将为改进风险管理模型算法提供积极支持，并促进专项研究反馈到各种复杂场景的馆藏文物预防性保护风险防控管理实践中。'}
            ],
            caseList:[
                {name:'文物材质对环境的要求',text:'馆藏壁画 | 青铜器 | 纺织品',img:'材质'},
                {name:'所在地域对文物的影响',text:'上海 | 陕西 | 四川',img:'地域'},
                {name:'保存环境对文物的影响',text:'展厅 | 库房 | 运输',img:'环境'},
                {name:'现有案例',text:'文物保护数字化四有平台',img:'案例'}
            ],
            dataList:[
                {name:'法律法规',txts:['·中华人民共和国文物保护法','·中华人民共和国文物保护法实施条例','·考古涉外工作办法']},
                {name:'规章制度',txts:['·馆藏文物保存环境监测 监测终端 基本要求','·馆藏文物保存环境控制 净化调湿装置','·馆藏文物保存环境监测 监测终端 紫外线']},
                {name:'文物保护',txts:['·明中期景德镇窑瓷器的外销与特点','·中国文物保护基金会成立30周年座谈会在京召开','·西安西大街古井出土瓷器赏析']},
                {name:'考古勘探',txts:['·首届“会馆问道”研讨会暨会馆文化研究交流中心揭牌仪式成功举办','·重视清前史研究有助于深入挖掘东北历史','·长沙窑瓷器：9世纪印度洋——中国海域地区全球型海上贸易的典型商品法']},
                {name:'博物馆',txts:['·文化广播影视新闻出版　文化遗产保护','·文化新闻出版　文物管理','·文化 新闻 出版　文物管理']},
                {name:'法律法规',txts:['·中华人民共和国文物保护法','·中华人民共和国文物保护法实施条例','·考古涉外工作办法']}
            ],
            testList:[
                {name:'基因对比',logo:'基因',img:"基因对比"},
                {name:'霉菌识别',logo:'霉菌',img:"环境风险"},
                {name:'害虫识别',logo:'害虫',img:"害虫识别"},
                {name:'环境风险',logo:'湿度',img:"环境风险"},
            ],
            appList:[
                {name:'平台接入案例',txt:'博物馆案列、公司设备接入案例等',img:'app案例'},
                {name:'平台接入规范',txt:'元数据元素规范、中心编码规范等',img:'app规范'},
                {name:'平台接入流程',txt:'全国中心、区域中心等接入流程',img:'app流程'},
            ]
        }
    },
    mounted(){
        this.timerChange()
        let list = fileOption.find(i=>{
                return i.type == 1
        }).children
        this.CGlist = list.map(el=>{
            let obj = el
            let child = fileList.filter(o => {
                return o.type ==  obj.type
            })
            if(child.length>4){
                child = child.slice(0, 3)
            }
            obj['children'] = child
            return obj
        })
    },
    beforeUnmount() {
        clearInterval(this.loopId)
        this.loopId = null
    },
    methods:{

        handleMouseEnter(index,$event){
            this.flag = true
            this.currentItem = index
            this.timer = 0
        },
        timerChange(){
            this.loopId = setInterval(() => {
                this.timer = this.timer + 1
                if(this.timer>10){
                    this.flag = false
                }
                if(!this.flag&&this.timer>10){
                    if(this.currentItem == 4){
                        this.currentItem = 0
                    }else{
                        this.currentItem = this.currentItem + 1
                    }
                }
            }, 2000);
        },
        
        goCase(){
            this.$router.push({
                path:'/openFiles'
            })
        },
        goData(){
            this.$router.push({
                path:'/openDataBank'
            })
        },
        goTest(){
            if(!this.user.zoneTree){
                this.$router.push({
                    path:'/login'
                })
                return
            }
            this.$router.push({
                path:'/openEnvironmentRisk'
            })
        },
        goApp(){
            this.$router.push({
                path:'/openTools'
            })
        },
        go(type){
            if(!this.user.zoneTree){
                this.$router.push({
                    path:'/login'
                })
                return
            }
            switch(type){
                case 0:
                    if(this.user.zoneTree.type==1){
                        this.urlResolve('countryScreenNew') 
                    }else{
                        this.$message.warning("暂无权限")
                    }
                    break;
                case 1:
                    if(this.user.zoneTree.type==1||this.user.zoneTree.type==2){
                        if(this.user.zoneTree.type==1){
                            this.urlResolve('countryScreenNew')
                        }else{
                            this.urlResolve('areaScreenNew') 
                        }
                    }else{
                        this.$message.warning("暂无权限")
                    }
                    break;
                case 2:
                    if(this.user.zoneTree.type==1||this.user.zoneTree.type==2||this.user.zoneTree.type==3){
                        if(this.user.zoneTree.type==1){
                            this.urlResolve('countryScreenNew')
                        }else if(this.user.zoneTree.type==2){
                            this.urlResolve('areaScreenNew')
                        }else{
                            this.urlResolve('siteHome') 
                        } 
                    }else{
                        this.$message.warning("暂无权限")
                    }
                    break;
                case 3:
                    console.log(this.user)
                    break;
            }
        },
        urlResolve(name){
            let routeUrl = this.$router.resolve({
                'name':name
            })
            window.open(`${routeUrl.href}`, '_blank')
        },
        BZopen(path){
            window.open(path,'_blank')
        },
        goMore(){
           this.$router.push("/openMoreFile4")
        }
    }
}
</script>
<style lang="scss" scoped>
.pageBox{
    width:100%;
    padding:0px;
}
.bannerBox{
    width:100%;
    height:280px;
    background-image: url('../../assets/loginout/home-banner.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: relative;
    .topText{
        position: absolute;
        bottom: 100px;
        left: 22%;
        width: 500px;
        height: 70px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        color: white;
        font-size: 20px;
        font-style: italic;
        .titleTop{
            font-size: 26px;
        }
    }
    .btnsBox{
        width: 130px;
        height: calc(100% - 60px);
        position: absolute;
        bottom: 1px;
        left: 0;
        .btnsItem{
            width: 100%;
            height: 25%;
            border-bottom: 1px solid #004DE8;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            border-right: 1px solid #004DE8;
            background-color: #00339A;
            cursor: pointer;
        }
    }
}
.title{
    width: 100%;
    display:flex;
    justify-content:center;
    align-items:center;
    height:100px;
    color:rgb(200, 195, 195);
    .titleCenter{
        margin:0px 20px;
        font-size:20px;
        color:#333;
        font-weight: 600;
    }
}
.JJBox{
    width: 100%;
    box-sizing: border-box;
    .jianjieContain{
        width: 1300px;
        height: 300px;
        margin:  0 auto;
        position: relative;
        display: flex;
        justify-content: space-around;
        align-items: center;
        .line{
            position: absolute;
            top: 50%;
            left: 0px;
            width: 100%;
            height: 1px;
            background-color: #c3c3c3;
            z-index: 0;
        }
        .boxItem{
            width: 200px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            position: relative;
            overflow: hidden;
            .sideItem{
                width: 100px;
                object-fit: contain;
                height: 60px;
                text-align: center;
                cursor: pointer;
            }
            .circle{
                width: 20px;
                margin: 30px 0px 30px 0px;
                height: 20px;
                border-radius: 10px;
                background-color: white;
                border: 1px solid #ddd;
                box-sizing: border-box;
                z-index: 1;
            }
            .bgText{
                width: 200px;
                height: 100%; 
                position: absolute;
                cursor: pointer;
                left: -200px;
                top: 0px;
                background-image: url('../../assets/loginout/homeT-bg.png');
                background-size: 100% 100%;
                background-repeat: no-repeat;
                z-index: 2;
                transition: left 0.5s;
                color: white;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                padding: 0px 10px;
                box-sizing: border-box;
                .bgTitle{
                    font-size: 20px;
                    line-height: 30px;
                    border-bottom: 2px solid white;
                }
                .bgTxt{
                    margin-top: 10px;
                    width: 100%;
                    max-height: calc(100% - 50px);
                    font-size: 12px;
                    text-align: justify;
                }
            }
            .bgOpen{
                left: -200px;  
            }
            .bgOpens{
                left: 0px; 
            }
            
        }
       
       
    }
}
.XMCGbox{
    width: 1200px;
    margin:0 auto;
    .CGBox{
      display: flex;
      justify-content: space-between;
      align-items: center;  
      flex-wrap: wrap;
      .CGitem{
        width: calc(50% - 10px);
        height: 220px;
        margin-bottom: 20px;
        padding: 10px 0px;
        background-color: #fff;
        box-sizing: border-box;
        border-radius: 10px;
        .lineTitle{
            padding: 0px 20px;
            box-sizing: border-box;
            line-height: 40px;
            font-size: 18px;
            display: flex;
 
            justify-content: space-between;
            border-bottom: 1px solid rgb(200, 200, 200);
            .more{
                color: rgb(169, 169, 169);
                font-size: 14px;
                cursor: pointer;
            }
        }
        .lineMsgBox{
            width: 100%;
            padding:0px 20px;
            box-sizing: border-box;
        }
        .lineMsg{
            width: 100%;
            height: 50px;
            line-height: 50px;
            border-bottom: 1px solid rgb(218, 218, 218);
            box-sizing: border-box;
            overflow: hidden;
            cursor: pointer;
            white-space: nowrap;
            text-overflow: ellipsis; 
        }
      }
      .CGitem:hover{
        box-shadow: 0px 0px 5px rgb(185, 185, 185);
      }
    }
}
.JDcaseBox{
    width: 1200px;
    margin:0 auto;
    .caseBox{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .caseItem{
            width: 260px;
            cursor:pointer;
            border-radius: 10px;
            overflow: hidden;
            background-color: white;
            .caseImg{
                width: 100%;
                height:150px;
                object-fit:cover;
            }
            .caseMsg{
                width: 100%;
                height: 100px;
                padding: 0px 15px;
                box-sizing: border-box;
                display: flex;
                justify-content: center;
                flex-direction: column;
                .caseTitle{
                    font-size: 16px;
                }
                .caseTip{
                    color: #666666;
                    font-size: 12px;
                    margin-top: 8px;
                }
                .caseSign{
                    width: 100%;
                    height: 20px;
                    text-align: right;
                }
            }
        }
    }
}
.dataBaseBox{
    width: 1200px;
    margin:0 auto; 
    .dataBox{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        .dataItem{
            width: 32%;
            cursor:pointer;
            background-color: white;
            border-radius: 10px;
            padding: 20px;
            box-sizing: border-box;
            margin-bottom: 10px;
            height: 220px;
            .topDI{
                width: 100%;
                height: 80px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 10px;
                .left{
                  display: flex;
                  justify-content: space-between;
                  flex-direction: column;
                  height: 70px;
                  .dataName{
                    font-size: 24px;
                  }
                  .dataLine{
                    width: 80px;
                    height: 5px;
                    background-color: #2FF3FF;
                    border-radius: 3px;
                    .dataIn{
                        width: 60%;
                        height: 100%;
                        background-color: #2F8CFF;
                        border-radius: 3px;
                    }
                  }
                }
                .right{
                    width: 80px;
                    height: 80px;
                    object-fit: contain;
                }
            }
            .botDI{
                color: #666666;
                font-size:14px;
                div{
                    margin-bottom: 4px;
                }
                //line-height: 30px;
            }
        }
    }
}
.testBaseBox{
    width: 1200px;
    margin: 0 auto;
    .testBox{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .testItem{
            cursor:pointer;
            width: 25%;
            .testTop{
                width: 100%;
                height: 160px;
                border: 1px solid #ddd;
                background-color: white;
                display: flex;
                justify-content: center;
                flex-direction: column;
                padding: 0px 30px;
                box-sizing: border-box;
                .testImg{
                    width:70px;
                    height:70px;
                    object-fit: contain;
                }
                .testTitle{
                    font-size: 20px;
                    margin-top: 18px;
                }
            }
            .testBot{
                width: 100%;
                height: 300px;
                object-fit: fill;
            }
        }
    }
}
.appBaseBox{
    width: 1200px;
    margin: 0 auto;
    margin-bottom: 50px;
    .appBox{
        width: 100%;
        display:flex;
        justify-content: space-between;
        align-items: center;
        .appItem{
            width: 30%;
            padding: 20px;
            box-sizing: border-box;
            background:linear-gradient(-90deg, #E8EBFF, #FFFFFF);
            border-radius: 10px 10px 10px 10px;
            display: flex;
            cursor: pointer;
            justify-content: space-between;
            align-items: center;
            min-height: 160px;
            .appLeft{
                width: calc(100% - 100px);
                .appName{
                    color: #333;
                    font-size: 22px;
                    margin-bottom: 20px;
                }
                .appTips{
                    color: #666666;
                    font-size: 14px;
                }
            }
            .appRight{
                width: 80px;
                height: 80px;
                object-fit: contain;
            }   
        }
        .appItem:hover .appName{
            color: #217DEF;
            font-size: 22px;
            margin-bottom: 20px;
        }
    }
}
.scan{
    position: fixed;
    top: 50%;
    right: 0;
    width: 120px;
    height: 150px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0px 5px rgb(163, 163, 163);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .scan-code{
        width: 100px;
        height: 100px;
        object-fit: contain;
    }
    .scan-name{
        display: flex;
        justify-content: flex-start;
        font-size: 12px;
        padding: 0px 5px;
        .scan-nameImg{
            width: 20px;
            height: 20px;
            object-fit: contain;
        }
        
    }
}

</style>